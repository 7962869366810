import { h, Component } from 'preact'

export default class Page404 extends Component {
  render () {
    return (
      <div className='Container404'>
        <h1>404</h1>
        <h1>Page Not Found</h1>
        <a href="/">Go to home page</a>
      </div>
    )
  }
}
